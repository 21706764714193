
import React from "react"
import Layout from "../../components/js/layout"
import SEO from "../../components/js/seo"

import Lectures from '../../components/js/reuseds/lectures'

import "../../components/css/page/univ-dc.css"

class UnivDiscount extends React.Component {

  state = {
    code: '',
    title: '',
    link: ''
  }

  render () {

    return (
      <Layout page="univ-dc">
        <SEO title="얄코강좌 깜짝 반값 할인" />
        <section id="univDc">
          <h1>
          얄코강좌 특별 반값 할인
            <span role="img" aria-label="books">🎁</span>
          </h1>
          <p className="pick-any">
            <span role="img" aria-label="down">🔽</span> 하단의 강좌들 중 반값 쿠폰을 받기 원하시는 강좌를 선택해주세요.
          </p>
          <div>
            <div className="info">
              <strong>아래의 이메일로</strong>으로 쿠폰을 요청하시면 <strong>쿠폰 번호</strong>를 보내드립니다.
            </div>
            <dl>
              <dt>받는이</dt>
              <dd>yalco@yalco.kr</dd>
              <dt><span role="img" aria-label="books">⭐</span> 메일 제목을 꼭!!! 아래와 같이 보내주세요.</dt>
              <dd>깜짝 쿠폰 요청 - (원하시는 강좌 이름)</dd>
              <dt>내용</dt>
              <dd>(내용은 없어도 좋습니다.)</dd>
            </dl>

            {/* <h3 className="special">
              <span role="img" aria-label="">🛑</span>
              책 리뷰 이벤트 종료
            </h3>
            <span className="note">
              이벤트를 위해 이미 책을 구매하신 분은(22/10/22 이전) 아래의 항목을 작성하여 보내주시면 쿠폰을 보내드립니다.
            </span>
            <dl>
              <dt>받는이</dt>
              <dd>yalco@yalco.kr</dd>
              <dt>메일 제목</dt>
              <dd>[책] 대학생 쿠폰 요청 - { lecture.code.toUpperCase() }</dd>
              <dt>내용</dt>
              <dd>1. 대학교 이름 (이메일 계정 확인용)</dd>
              <dd>
                2. 도서  구매내역 캡쳐 (개인정보는 가려주세요!)
              </dd>
              <dd>
                3. 구매한 곳에서 사이트에서 작성한 리뷰 캡쳐
              </dd>
            </dl> */}
          </div>
        </section>
        <section id="lecturesCon">
          <Lectures></Lectures>
        </section>
      </Layout>
    )
  }


}

export default UnivDiscount